import React from 'react';
import get from 'lodash.get';
import * as styles from './CarbonCallout.module.scss';

import useEcologiDataHook from '../../hooks/useEcologiDataHook';

import LinkButton from '../Buttons/LinkButton/LinkButton';
import IconLeaf from '../Icons/IconLeaf';
import StatBlock from '../StatBlock/StatBlock';

import ecologiImg from '../../images/ecologi--white.svg';
import greatGreenWallImg from '../../images/great-green-wall.png';
import carbonFootprintImg from '../../images/carbon-footprint--white.svg';

const CarbonCallout = (): JSX.Element => {
    const data = useEcologiDataHook();

    if (!data) {
        // TODO: Maybe Add a small loading state?
    }

    const trees = get(data, 'trees', '-').toLocaleString('en');
    const carbonOffset = get(data, 'carbonOffset', '-');
    const monthsActive = get(data, 'monthsActive', '-');

    return (
        <section className={styles.CarbonCallout}>
            <div className={`${styles.CarbonCalloutFrame} contain containMedium`}>
                <div className={styles.CarbonCalloutBody}>
                    <div className="space-y-3">
                        <h2 className="text-subtitle"><span>We are a carbon negative building company</span>&nbsp;<IconLeaf size="32px" stroke="bronze-400" /></h2>
                        <p className="text-paragraph">The UK building industry is responsible for a significant portion of  our national carbon footprint. We want to be part of changing that.</p>
                        <LinkButton href="/carbon-manifesto" label="Read our manifesto" bordered />
                    </div>
                </div>
                <div className={styles.CarbonCalloutStats}>
                    <StatBlock
                        data={
                            [
                                {
                                    figure: monthsActive,
                                    label: 'months climate positive'
                                },
                                {
                                    figure: trees,
                                    label: 'trees planted'
                                },
                                {
                                    figure: carbonOffset,
                                    label: 'tonnes of CO2 offset this year'
                                }
                            ]
                        }
                    />
                    <div className="flex gap-4 items-center justify-between max-w-0.25 m-auto">
                        <div className="shrink">
                            <img className="block" src={ecologiImg} alt="Ecologi" width="100" />
                        </div>
                        <div className="shrink">
                            <img className="block" src={greatGreenWallImg} alt="Great Green Wall" width="100" />
                        </div>
                        <div className="shrink">
                            <img className="block" src={carbonFootprintImg} alt="Carbon footprint standard" width="60" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default CarbonCallout;
