import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import Hero from '../components/Hero/Hero';
import ProjectList, { ProjectRepeaterEntity } from '../components/ProjectList/ProjectList';
import ProjectCallout from '../components/ProjectCallout/ProjectCallout';
import Decorator from '../components/Decorator/Decorator';
import Seo from '../components/Seo/Seo';
import CarbonCallout from '../components/CarbonCallout/CarbonCallout';

export interface ProjectListingProps {
    data: {
        contentfulGenericPage: {
            title: string,
            heroImage?: {
                url: string
            }
            pageBody: ProjectRepeaterEntity[];
        };
    };
}

const ProjectsPage = ({ data }: ProjectListingProps): JSX.Element => {
    const { title: pageTitle } = data.contentfulGenericPage;
    const heroImage = (data.contentfulGenericPage.heroImage) ? `${data.contentfulGenericPage.heroImage.url}?fm=webp&w=1920` : undefined;
    const projectRepeaters = data.contentfulGenericPage.pageBody;

    return (
        <Fragment>
            <Seo title={pageTitle} />
            <div className="space-y-6">
                <Hero height="small" background={heroImage}>
                    <Hero.Body className="text-center" padding="medium">
                        <div className="space-y-1 m-auto max-w-0.25 text-center">
                            <Hero.Title className="gold">Our projects</Hero.Title>
                            <Hero.Subtitle>A portfolio across the domestic and commercial sectors</Hero.Subtitle>
                            <Decorator />
                        </div>
                    </Hero.Body>
                </Hero>
                <section className="contain containLarge space-y-7">
                    {
                        projectRepeaters.map((repeater) => (
                            <ProjectList key={repeater.id} spacing={7} projectRepeater={repeater} />
                        ))
                    }
                </section>
            </div>
            <ProjectCallout />
            <CarbonCallout />
        </Fragment>
    );
};

export const pageQuery = graphql`
query ProjectsListContentQuery {
    contentfulGenericPage(slug: {eq: "projectsListing"}) {
        title
        heroImage {
            url
        }
        pageBody {
          ... on ContentfulProjectListRepeater {
            id
            type
            projects {
              id
              title
              slug
              tags
              heroImage {
                gatsbyImageData(
                    width: 1920,
                    layout: FULL_WIDTH
                )
                description
              }
              description {
                  raw
              }
            }
          }
        }
      }
  }
`;

export default ProjectsPage;
